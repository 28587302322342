<template>
    <div>
        <v-toolbar dense color="primary" flat dark>
            <v-toolbar-title>Reporte de cirugias ({{movimientosTotal}})</v-toolbar-title>
            <v-spacer/>
            <v-btn @click="busca">
                Buscar
            </v-btn>
        </v-toolbar>
        <v-sheet class="pa-2" elevation="2">
            <v-row no-gutters>
                <v-col md=3 cols="12">
                    <v-date-picker
                        v-model="fechaPicker"
                        range
                        full-width
                        no-title
                        scrollable
                        :max="estaFecha"
                        locale="es-mx"
                    />
                </v-col>
                <v-col md=9 cols="12">

                </v-col>
            </v-row>
            <v-data-table
                class="mt-2"
                :headers="headers"
                :items="movimientosTodos"
                hide-default-footer
                item-key="id"
                :loading="loadingDatos"
                dense
                :server-items-length='movimientosTotal'
                disable-pagination
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip
                        class="my-1"
                        dark
                        :color="statusCirugia[item.status].color"
                    >
                        {{statusCirugia[item.status].text}}
                    </v-chip>
                </template>
            </v-data-table>
        </v-sheet>



    </div>
</template>

<script>
export default {
    mounted(){
        this.fechaPicker = [
            this.moment(new Date()).format("YYYY-MM-DD"),
            this.moment(new Date()).format("YYYY-MM-DD"),
        ]
    },
    data:()=>({
        loadingDatos:false,
        db:{},
        fechaPicker:[],

        statusCirugia:{
            1:{
                color:'purple',
                text:'Solicitada',
            },
            2:{
                color:'blue',
                text:'Agendada',
            },
            3:{
                color:'orange',
                text:'Sala',
            },
            4:{
                color:'orange',
                text:'Recuper',
            },
            5:{
                color:'green',
                text:'Realizada',
            },
            90:{
                color:'red',
                text:'Reagenda',
            },
            99:{
                color:'black',
                text:'Cancelada',
            },
        },

        headers:[
            { text: 'Solicita', value: 'solicita', align:'center', groupable:false},
            { text: 'Asigna', value: 'asigna', align:'center', groupable:false},
            { text: 'Estado', value: 'status', align:'center', width:'40px', groupable:false},
            { text: 'C.I.P.', value: 'cip', align:'center'},
            { text: 'Médico', value: 'staff', align:'center', width:'40px'},
        ],
        movimientosTodos:[],
        movimientosTotal:0,
    }),
    methods:{
        async busca(){
            this.loadingDatos = true
            try{
                this.loadingDatos = false
                const req = await this.$http({
                    method:"POST",
                    url:"/reportes/reporteCirugias",
                    params:this.db,
                })
                this.loadingDatos=false
                console.log(req.data)
                this.movimientosTodos=req.data.root
                this.movimientosTotal=req.data.total

            } catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.loadingDatos = false
            }
        },
    },
    computed:{
        estaFecha(){
            return (this.moment(new Date()).format('YYYY-MM-DD'))
        },
    },
    watch:{
        fechaPicker(){
            let far=[]
            // this.fechaArr = []
            if(this.fechaPicker.length > 0){
                this.fechaPicker.forEach(fecha => {
                    far.push(new Date(fecha+'T00:00:00'))
                })

                this.db.fechaDe = this.moment(new Date(Math.min.apply(null,far))).format("DD-MM-YYYY")
                this.db.fechaA = this.moment(new Date(Math.max.apply(null,far))).format("DD-MM-YYYY")
            }
        },
    },
}
</script>

<style>

</style>